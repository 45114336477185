






































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CartCommissionList from "@/components/shop/cart/commission/CartCommissionList.vue";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import {
    Article,
    ArticlePrice,
    OrderStatus,
    OrderPaymentType,
} from "@/types/shop";
import { CartCommission } from "@/store/modules/cart/types";
import { UserAddress } from "@/types/user/address";
import { ParcelTracking } from "@/types/shop/order";

/**
 * OrderListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionList,
        ArticlePriceLabel,
    },
})
export default class OrderListItem extends Vue {
    @Prop({ type: Number, required: true }) readonly id!: number;
    @Prop({ type: String, required: true }) readonly orderDate!: string;
    @Prop({ type: Object, required: true }) readonly status!: OrderStatus;
    @Prop({ type: Object, required: true }) readonly address!: UserAddress;
    @Prop({ type: Object, required: true })
    readonly paymentType!: OrderPaymentType;
    @Prop({ type: Array, required: true })
    readonly commissions!: CartCommission<Article>[];
    @Prop({ type: Object, required: true }) readonly totalPrice!: ArticlePrice;
    @Prop({ type: String, default: null }) readonly cancelHash!: string | null;
    @Prop({ type: Object, default: null })
    readonly tracking!: ParcelTracking | null;

    private showTrackingIFrame = false;
}
