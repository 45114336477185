








import { Component, Vue } from "vue-property-decorator";
import OrderList from "@/components/shop/order/OrderList.vue";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { OwnOrder } from "@/types/shop/order";

/**
 * ShopUserOrderDetails view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        OrderList,
    },
})
export default class ShopUserOrderDetails extends Vue {
    private orders: OwnOrder[] = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.orders = await this.fetchOrders();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Cancels the given order
     *
     * @param order order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async cancelOrder(order: OwnOrder) {
        if (!order.cancelHash) return;

        try {
            const response = (
                await axios.post<APIResponse>(
                    "/orders/cancel/" + order.cancelHash
                )
            ).data;
            if (response.status === "error") {
                throw new Error(response.message || "unknownError");
            }
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all orders
     *
     * @returns Promise<OwnOrder[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchOrders(): Promise<OwnOrder[]> {
        const response = (await axios.get<APIResponse<OwnOrder[]>>("/orders"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
