



















import { Component, Prop, Vue } from "vue-property-decorator";
import OrderListItem from "./OrderListItem.vue";
import { OwnOrder } from "@/types/shop/order";

/**
 * OrderList component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        OrderListItem,
    },
})
export default class OrderList extends Vue {
    @Prop({ type: Array, required: true }) readonly orders!: OwnOrder[];
}
